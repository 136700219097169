// 公共接口
import request from '../utils/request'
// import qs from 'qs'

//图片验证码生成
export function gen(data) {
  return request({
      method: 'POST',
      url: 'captcha/gen',
      data
  })
}

//图片验证码校验
export function check(data) {
  return request({
      method: 'POST',
      url: 'captcha/check',
      data
  })
}

//0010013-对象存储生成token
export function getCOSSecretKey(data) {
  return request({
      method: 'POST',
      url: 'cos/genToken',
      data
  })
}

// 0010025-我的课程分页接口
export function getCourseList(params = {}) {
  return request({
      method: 'GET',
      url: 'userCenter/course',
      params
  })
}

// 0010101-我的证书
export function getUserCenterCert(params = {}) {
  return request({
      method: 'GET',
      url: 'userCenter/cert',
      params
  })
}

//0010012-修改绑定手机号
export function modify(data) {
  return request({
      method: 'POST',
      url: 'user/phone/modify',
      data
  })
}

// 0010045-用户详细信息-查询
export function getUserDetail(params = {}) {
  return request({
      method: 'GET',
      url: 'user/detail',
      params
  })
}

// 0010044-用户详细信息-保存
export function postUserDetail(data) {
  return request({
      method: 'POST',
      url: 'user/detail',
      data
  })
}

//0010118-通知公告-分页
export function getNoticeList(params = {}) {
  return request({
      method: 'GET',
      url: 'sys/notice',
      params
  })
}

//0010119-通知公告-详情
export function notice(id) {
  return request({
      method: 'GET',
      url: `sys/notice/${id}`,
  })
}

//0010039-查询用户实名信息
export function getRealName(params = {}) {
  return request({
      method: 'GET',
      url: 'realname',
      params
  })
}

//轮播图
export function getImgDataList(type) {
  return request({
      method: 'GET',
      url: `sys/img/${type}`,
  })
}