<template>
  <div class="approval">
    <div style="text-align: left;"><Head title="实名认证"></Head></div>
    <div class="cont el">
      <div v-if="isReal" class="info">
        <div class="title">
          <p>
            <img
                src="../../../assets/passed.jpeg"
            />
          </p>
          <p class="txt">已实名认证</p>
        </div>
        <div class="cont none">
          <div class="w300">
            <p>真实姓名：{{ name }}</p>
            <p>身份证号：{{ idCard }}</p>
          </div>
        </div>
      </div>
      <div v-else class="info">
        <p class="txt">未实名认证</p>
        <div class="code">
          <img
              src="../../../assets/QRCodeIMG.jpg"
              alt=""
          />
          <div class="desc">请用微信扫描二维码登录小程序进行实人认证</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getRealName } from '@/api/common'
import Head from './Head'
export default {
  name: 'Approval',
  components: {

    Head
  },
  data() {
    return {
      isReal: true, //是否已实名
      name: '',
      idCard: ''
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted() {
    this.getRealName()
  },
  methods: {
    getRealName() {
      getRealName().then((res) => {
        if (res.data.code === 0) {
          console.log(res)
          this.isReal = true // 已实名
          this.name = res.data.data.name
          this.idCard = res.data.data.idCard
        } else {
          this.isReal = false
          /* this.$message({
            type: 'error',
            message: res.data.message
          }) */
        }
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.approval {
  background: #fff;
  em {
    display: flex;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .cont {
    border-top: 1px solid #f5f5f5;
    .info {
      margin: 40px 0;
      font-weight: bold;
      // text-align: center;
      .title {
        p {
          .img {
            width: 140px;
            height: 80px;
          }
        }
        .txt {
          margin-top: 20px;
        }
      }
      .cont {
        margin-top: 10px;
        padding-top: 10px;
        p {
          font-size: 14px;
          font-weight: normal;
          margin: 10px 0;
          color: #333;
        }
      }
    }
    .code {
      margin-top: 20px;
      img {
        width: 200px;
        height: 200px;
      }
      .desc {
        color: #999;
        margin-top: 20px;
        font-size: 12px;
      }
    }
    .w300 {
      width: 300px;
      margin: 0 auto;
      p {
        text-align: left;
        margin: 20px 0!important;
      }
    }
  }
  .none{
    border-top:none;
  }
  .el{
    padding: 10px 20px;
    margin-top: 0px !important;
    // min-height: 62vh;
  }
}
</style>